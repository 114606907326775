import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { Spin, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import '../../css/login.scss';
import logo from "../../images/ormelogo.svg";
import { postLogin } from '../../redux/actions/login.action';
import axios from 'axios';
import { BASEURL } from '../../config';

export default function Login() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [spinner, setSpin] = useState(false);
  const { login } = useSelector((state) => {
    return state;
  });

  const [error, setError] = useState('');
  const [sidLoading, setSidLoading] = useState(false);

  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);

  useEffect(() => {
    if (searchParams.get('sid') !== null) {
      getSidUrl(searchParams.get('sid'));
    }
  }, []);

  const getSidUrl = async (sid) => {
    setSidLoading(true);
    await axios
      .post(`${BASEURL}signin/session`, { sid: sid })

      .then((res) => {
        localStorage.setItem('userInfo', JSON.stringify(res.data.message));
        history('/events');
        window.location.reload();
      })
      .catch((err) => {
        notification.error({
          message: err.response.data.message,
          className: 'toast-error',
        });
        setTimeout(() => {
          window.location.replace('https://www.kbshop.com/');
        }, 1000);
      });
  };

  const onFinish = (values) => {
    setSpin(true);
    dispatch(postLogin(values)).then((res) => {
      console.log(res, 'res');

      if (res?.payload?.success) {
        console.log('if');

        localStorage.setItem('userInfo', JSON.stringify(res?.payload?.message));
        history('/login');
        window.location.reload();

        setSpin(false);
        // window.location.reload();
      } else {
        console.log('else');

        setError(Object?.keys(res?.payload?.data?.message)?.length == 0 ? 'Something Went Wrong' : res?.payload?.data?.message);
        setSpin(false);
      }
    }).catch((err) => {
      console.log(err, 'err');

    })
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  if (!sidLoading) {
    return (
      <>
        <div className="login-main">
          <div className="login-left">
            {/* <div className="login-left-content">
              <div className="intro-main">
                <h1>Introducing</h1>
                <h2>LIVE SHOPPING</h2>
                <p>
                  Boost your sales, create authentic and engaging relationships
                  with your customers, in an innovative way!
                </p>
              </div>
            </div> */}
          </div>
          <div className="login-right">
            <div className="login-right-content form-styling">
              <div className="logo-login">
                <Link to="/">
                  {' '}
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <h2 className="login-hd">Login</h2>
              <Form
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input size="large" placeholder="example@email.com" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password size="large" placeholder="password" />
                </Form.Item>

                <p className="ant-form-item-explain-error text-center">
                  {error}
                </p>

                <Form.Item className="flex">
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  {/* <a className="login-form-forgot float-right" href="/forgot-password">
                  Forgot password
                </a> */}
                  {/* <Link
                    to="/forgot-password"
                    className="login-form-forgot float-right"
                  >
                    Forgot password
                  </Link> */}
                </Form.Item>

                <Form.Item>
                  <Spin spinning={spinner}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="default-btn login-btn"
                    >
                      Login
                    </Button>
                  </Spin>
                </Form.Item>
              </Form>
              {/* New to KBLIVE?{' '}
              <Link className="signin-link" to="/register">
                Create an Account
              </Link> */}

            </div>
            <div className='privacy-link'>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <span className="spinclass loader-center">
        <Spin size="large" />
      </span>
    );
  }
}
