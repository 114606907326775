import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import Content from "./Content";
import LoginModal from "./LoginModal";
import SelectPages from "./SelectPages";
import "../../css/messaging.scss";
import { Form, Input, Button, Tabs } from "antd";
import SocialMessaging from "./socialMessaging";

const { TabPane } = Tabs;

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [checkLoading, setCheckLoading] = useState(false);
  const [insta, setInsta] = useState(0);
  const [complete, setComplete] = useState(false);
  const [appName, setAppName] = useState();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).fb_token;
    const fbPage = JSON.parse(localStorage.getItem("userInfo")).page_token;
    if (token) {
      setFacebookUserAccessToken(token);
      console.log(fbPage);
      if (fbPage) {
        setSelectedPage(fbPage);
        setComplete(true);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  const fbLogin = () => {
    // const secret = 'e930fa5b68d5d128c0eb3081dd003da1'
    // const secret = 'e930fa5b68d5d128c0eb3081dd003da1'
    // const secret='e930fa5b68d5d128c0eb3081dd003da1'
    // const client = '296013448922683'
    // const secret = '73a28a599afc60d1d82b2c7e0c15ef9b'
    // const client = '916894299469996'

    const secret = "f89a7fb3153465bd76f47f4f0b664fbf";
    const client = "6305473309571543";

    // const client='296013448922683'
    const url = "https://get.konnect.bio";
    window.FB.login(
      (response) => {
        console.log(response.authResponse?.accessToken, "        olddddddd");

        if (response.authResponse?.accessToken) {
          fetch(`https://graph.facebook.com/v17.0/oauth/access_token?  
                        grant_type=fb_exchange_token&          
                        client_id=${client}&
                        client_secret=${secret}&
                        fb_exchange_token=${response.authResponse?.accessToken}`)
            .then((res) => res.json())
            .then((json) => {
              setFacebookUserAccessToken(json.access_token);
              var userInfo = JSON.parse(localStorage.getItem("userInfo"));
              userInfo.page_token = json.access_token;
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
            });
        }
      },
      {
        // Scopes that allow us to publish content to Instagram
        scope: "instagram_basic, pages_show_list, instagram_manage_comments, pages_manage_metadata, instagram_manage_messages, pages_read_engagement, business_management, instagram_manage_insights, instagram_content_publish, pages_manage_posts, pages_messaging",
      }
    );
  };

  const getFacebookPages = () => {
    console.log("ffff", facebookUserAccessToken);
    axios
      .get(
        `https://graph.facebook.com/v17.0/me/accounts?access_token=${facebookUserAccessToken}`
      )
      .then((res) => {
        setPages(res.data.data);
        setAppName(res.data.data?.[0]?.name)
      });
    // window.FB.api(
    //     "me/accounts",
    //     { access_token: facebookUserAccessToken },
    //     (response) => {
    //         setPages(response.data);
    //     }
    // );
  };

  const getInstagramAccountId = (facebookPageId) => {
    setCheckLoading(true);
    axios
      .get(
        `https://graph.facebook.com/v17.0/${facebookPageId}?fields=instagram_business_account&access_token=${facebookUserAccessToken}`
      )
      .then((res) => {
        setInsta(res.data.instagram_business_account?.id);
        setCheckLoading(false);
      });
    // setCheckLoading(true)
    // window.FB.api(
    //     facebookPageId,
    //     {
    //         access_token: facebookUserAccessToken,
    //         fields: "instagram_business_account",
    //     },
    //     (response) => {
    //         setInsta(response.instagram_business_account?.id);
    //         setCheckLoading(false)
    //     }
    // );
  };

  const pagesMemo = useMemo(() => {
    if (facebookUserAccessToken) {
      getFacebookPages();
    }
  }, [facebookUserAccessToken]);

  const idMemo = useMemo(() => {
    if (selectedPage) {
      console.log("calling");
      getInstagramAccountId(selectedPage);
    }
  }, [selectedPage]);

  const logOutOfFB = () => {
    localStorage.removeItem("fbPage");
    localStorage.removeItem("fbToken");
    setFacebookUserAccessToken("");
    setComplete("");
  };

  console.log("facebookUserAccessToken", facebookUserAccessToken);
  console.log("complete", complete);

  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [gridActive, setGridActive] = useState(true);
  const [socialTitle, setSocialTitle] = useState(
    searchParams.get('type') === null ? 'instagram' : searchParams.get('type')
  );
  const [socialStatus, setSocialStatus] = useState(123);

  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set('type', key);
    window.history.replaceState(null, null, url);
    setSocialTitle(key);
    setSocialStatus(event.timeStamp);
  };
  return (
    <div>
      {/* <div className='container p-0 ml-0'>
                <div className="row">
                    <div className="col-lg-6 col-xl-6">
                        <div className="messaging-box-white">
                            <div className='username-left'>
                                Shopper 95
                            </div>
                            <ul className='message-box-list scrollbar-style'>
                                <li className='message-box receive-box'>
                                    <div className='message-content'>
                                        <div className='message-txt'>test message 1</div>
                                        <div className='message-date'>Tue, 07 Jan 2023 12:15:06 GMT</div>
                                    </div>
                                </li>
                                <li className='message-box receive-box'>
                                    <div className='message-content'>
                                        <div className='message-txt'>test message 1</div>
                                        <div className='message-date'>Tue, 07 Jan 2023 12:15:06 GMT</div>
                                    </div>
                                </li>
                                <li className='message-box sent-box'>
                                    <div className='message-content'>
                                        <div className='message-txt'>test message 2 reply</div>
                                        <div className='message-date'>Tue, 07 Jan 2023 12:15:06 GMT</div>
                                    </div>
                                </li>
                                <li className='message-box receive-box'>
                                    <div className='message-content'>
                                        <div className='message-txt'>test message 1</div>
                                        <div className='message-date'>Tue, 07 Jan 2023 12:15:06 GMT</div>
                                    </div>
                                </li>
                            </ul>

                            <div className='messaging-send-main'>

                                <Form
                                    className="messaging-send-box">
                                    <Input
                                        className="message-field"
                                        type="text"
                                        placeholder="Write your message..."
                                    // onChange={handleChange}
                                    />
                                    <Button
                                        className="submit message-button"
                                    >
                                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                    </Button>
                                </Form>

                            </div>

                        </div>
                    </div>
                </div>
            </div> */}
      <div className="grid-listing-area flex-column  flex-sm-row align-items-start align-items-sm-center mb-30">
        <div className="grid-listing-left flex-grow-1">
          <Tabs
            defaultActiveKey={socialTitle}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            <TabPane tab="Instagram" key="instagram"></TabPane>
            <TabPane tab="Messenger" key="messenger"></TabPane>
          </Tabs>
        </div>

      </div>

      <SocialMessaging
        className={gridActive}
        title={socialTitle}
        socialStatus={socialStatus}
      />
    </div>
  );
}
