import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
// import { useNavigate } from 'react-router-dom';
import SelectPages from "./SelectPages";
import { BASEURL } from "../../config";
import { notification } from "antd";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory({
  forceRefresh: true,
});

function ConnectToFb({ username, setFbPageLocal, userId }) {
  // const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [checkLoading, setCheckLoading] = useState(false);
  const [insta, setInsta] = useState(0);
  const [complete, setComplete] = useState(false);
  const [modal, setModal] = useState(false);

  const axiosHeader = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo"))?.token
        }`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).fb_token;
    const fbPage = JSON.parse(localStorage.getItem("userInfo")).page_token;
    if (token) {
      setFacebookUserAccessToken(token);
      // console.log(fbPage);
      if (fbPage) {
        setSelectedPage(fbPage);
        setComplete(true);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  const fbLogin = () => {
    // const secret = 'e930fa5b68d5d128c0eb3081dd003da1';
    // const client = '296013448922683';

    // const secret = '73a28a599afc60d1d82b2c7e0c15ef9b';
    // const client = '916894299469996';

    const secret = "f89a7fb3153465bd76f47f4f0b664fbf";
    const client = "6305473309571543";

    const url = "https://www.dev.kblive.com";
    window.FB.login(
      (response) => {
        // console.log(response.authResponse?.accessToken, "        olddddddd");
        if (response.authResponse?.accessToken) {
          fetch(
            `https://graph.facebook.com/v17.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${client}&client_secret=${secret}&fb_exchange_token=${response.authResponse?.accessToken}`
          )
            .then((res) => res.json())
            .then((json) => {
              setFacebookUserAccessToken(json.access_token);
              // localStorage.setItem('fbToken', json.access_token)
              axios
                .post(
                  `${BASEURL}social/fb/token/user`,
                  {
                    fbToken: json.access_token,
                  },
                  axiosHeader
                )
                .then((res) => {
                  var userInfo = JSON.parse(localStorage.getItem("userInfo"));
                  userInfo.fb_token = json.access_token;
                  localStorage.setItem("userInfo", JSON.stringify(userInfo));
                });
            });
        }
      },
      {
        // Scopes that allow us to publish content to Instagram
        scope: "instagram_basic, pages_show_list, instagram_manage_comments, pages_manage_metadata, instagram_manage_messages, pages_read_engagement, business_management, instagram_manage_insights, instagram_content_publish, pages_manage_posts, pages_messaging",
        // scope: "pages_show_list, pages_manage_posts, instagram_content_publish, pages_read_engagement, instagram_basic, instagram_manage_comments, instagram_manage_insights, pages_manage_metadata, instagram_manage_messages, business_management",
      }
    );
  };

  const getInstagramAccountId = (facebookPageId) => {
    setCheckLoading(true);
    axios
      .get(
        `https://graph.facebook.com/v17.0/${facebookPageId}?fields=instagram_business_account&access_token=${facebookUserAccessToken}`,
        axiosHeader
      )
      .then((res) => {
        setInsta(res.data.instagram_business_account?.id);
        setCheckLoading(false);
      });
    // window.FB.api(
    //     facebookPageId,
    //     {
    //         access_token: facebookUserAccessToken,
    //         fields: "instagram_business_account",
    //     },
    //     (response) => {
    //         setInsta(response.instagram_business_account?.id);
    //         setCheckLoading(false)
    //     }
    // );
  };
  const getFacebookPages = () => {
    // console.log("ffff", facebookUserAccessToken);
    axios
      .get(
        `https://graph.facebook.com/v17.0/me/accounts?access_token=${facebookUserAccessToken}`,
        axiosHeader
      )
      .then((res) => {
        setPages(res.data.data);
      });
    // window.FB.api(
    //     "me/accounts",
    //     { access_token: facebookUserAccessToken },
    //     (response) => {
    //         console.log("aaaa",response)
    //         // setPages(response.data);
    //     }
    // );
  };
  const pagesMemo = useMemo(() => {
    if (facebookUserAccessToken) {
      getFacebookPages();
    }
  }, [facebookUserAccessToken]);

  const idMemo = useMemo(() => {
    if (selectedPage) {
      // console.log("calling");
      getInstagramAccountId(selectedPage);
    }
  }, [selectedPage]);

  const refreshToken = () => {
    axios
      .get(`${BASEURL}social/fb/refreshfacebooktoken`, axiosHeader)
      .then((res) => {
        // toast.success(res?.data?.message);
        notification.success({
          message: res?.data?.message,
          className: "toast-success",
        });
      });
  };

  const logOutOfFB = () => {
    axios
      // .put(`/users/revise/disconnectinstagram/${userId}`)
      .put(`${BASEURL}users/revise/resetfacebook/${userId}`, {}, axiosHeader)
      .then((response) => {
        // localStorage.removeItem("access_token");
        // localStorage.removeItem("fbToken");
        // localStorage.removeItem("fbPage");
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        userInfo.page_token = "";
        userInfo.fb_token = "";
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        setModal(false);
        history.push("/account/social");
        history.go(0);
      });
  };
  return (
    <div class="conn-set-inner">
      {facebookUserAccessToken && complete ? (
        <span className="connection-status-badge-green">Connected</span>
      ) : (
        <span className="connection-status-badge-red">Not Connected</span>
      )}
      <div class="con-set-inner-1">
        <div class="con-set-inner-2">
          <h5>
            Facebook Connection
            {/* <button
              onClick={() => refreshToken()}
              className="btn btn-primary refresh-btn"
            >
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </button> */}
          </h5>
          <div class="row">
            <div class="col-md-12">
              <div class="dp_fields-setup mb-3">
                <div class="category_count">Connection Status</div>
              </div>
              <div class="dp_fields-setup">
                {loading ? (
                  <p>...Loading</p>
                ) : facebookUserAccessToken ? (
                  !complete ? (
                    <SelectPages
                      selectedPage={selectedPage}
                      setSelectedPage={(pageId) => {
                        setSelectedPage(pageId);
                      }}
                      pages={pages}
                      insta={insta}
                      clear={setFacebookUserAccessToken}
                      next={() => {
                        setComplete(true);
                        axios
                          .post(
                            `${BASEURL}social/fb/token/page`,
                            {
                              fbPage: selectedPage,
                              instaId: insta,
                              fb_page_access_token: facebookUserAccessToken,
                            },
                            axiosHeader
                          )
                          .then(() => {
                            var obj = localStorage.getItem("userInfo");
                            var userInfo = JSON.parse(obj);

                            userInfo.page_token = selectedPage;
                            userInfo.instagram_id = insta;
                            localStorage.setItem(
                              "userInfo",
                              JSON.stringify(userInfo)
                            );
                          });
                        axios
                          .get(
                            `https://graph.facebook.com/${selectedPage}?fields=access_token&access_token=${facebookUserAccessToken}`,
                            axiosHeader
                          )
                          .then((res) => {
                            axios.post(
                              `https://graph.facebook.com/v17.0/${selectedPage}/subscribed_apps?subscribed_fields=feed&access_token=${res.data.access_token}`,
                              axiosHeader
                            );
                          });
                        // localStorage.setItem('fbPage', selectedPage)
                        setFbPageLocal(selectedPage);
                      }}
                      checkLoading={checkLoading}
                    />
                  ) : (
                    <div className="dp_fields-setup">
                      <div className="connected-text text-left mb-2">
                        Connected{" "}
                        {pages.filter((it) => it.id === selectedPage)[0]
                          ?.name &&
                          ": " +
                          pages.filter((it) => it.id === selectedPage)[0]
                            ?.name}
                      </div>
                      <Button
                        variant="primary"
                        className="default-btn medium-btn w-100"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        Disconnect Facebook
                      </Button>
                    </div>
                  )
                ) : (
                  <>
                    <div className="connected-text text-left mb-2 text-danger">
                      Not Connected
                    </div>
                    <Button
                      // disabled={username !== '' ? false : true}
                      onClick={fbLogin}
                      variant="primary"
                      className="default-btn medium-btn w-100"
                    >
                      <i className="fa fa-facebook" />
                      &nbsp;&nbsp; Connect Facebook
                    </Button>
                  </>
                )}
              </div>
              <Modal
                show={modal}
                onHide={() => setModal(false)}
                className="change-password"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Disconnect Facebook and Instagram</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                  Are you sure you want to disconnect both facebook and
                  instagram accounts?
                  <span className="strong"></span> This will remove all your
                  content from our platform.
                  <p>This action is not reversible.</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => {
                      setModal(false);
                    }}
                    variant="outline-primary"
                  >
                    Close
                  </Button>
                  <Button
                    className="btn-danger"
                    onClick={() => {
                      logOutOfFB();
                    }}
                  >
                    Yes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectToFb;
