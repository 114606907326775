import { Spin } from 'antd'
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { BASEURL } from '../../config'
import { Typography } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { Row, Button, Col, Spinner } from 'react-bootstrap';
import ReactPlayer from 'react-player'


const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export default function Posts() {
    const [posts, setPost] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.post(`${BASEURL}social/ig/media/${userInfo?.access_token}`,
            {
                username: userInfo?.username
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`,
                }
            })
            .then((res) => {
                setPost(res.data)
                setLoading(false)

            })
    }, [])

    function intlFormat(num) {
        return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    }
    function numberFormat(num) {
        if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
        if (num >= 1000) return intlFormat(num / 1000) + 'k';
        return intlFormat(num);
    }


    console.log(posts, 'posts');
    if (loading) {
        return (
            <span className="spinclass loader-center position-relative">
                <Spin size="large" />
            </span>
        )
    }
    return (
        <>
            {/* <div className='post-info'>

                <h6>
                    <a target="_blank" href={`https://www.instagram.com/${userInfo?.username}`}>
                        {userInfo?.username}
                    </a>
                </h6>
            </div> */}
            <Card sx={{ marginTop: 2 }} className='post-info'>
                {
                    posts?.getProfile ?
                        <>
                            <CardContent>
                                <Row>
                                    <Col
                                        xl={2}
                                        lg={3}
                                        sm={12}
                                        className="d-flex flex-column justify-content-center align-items-center"
                                    >
                                        <Card
                                            elevation={3}
                                            sx={{ maxWidth: 200, borderRadius: '50%' }}
                                        >
                                            <img
                                                style={{ width: '100%' }}
                                                src={posts?.getProfile?.profile_picture_url}
                                            />
                                        </Card>
                                    </Col>
                                    <Col
                                        xl={10}
                                        lg={9}
                                        sm={12}
                                        className="d-flex flex-column justify-content-center"
                                    >
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            sx={{ color: 'gray', marginTop: 1 }}
                                        >
                                            <a
                                                target="_blank"
                                                href={`https://www.instagram.com/${posts?.getProfile?.username}/`}
                                                style={{ color: 'gray' }}
                                            >
                                                {posts?.getProfile?.username}
                                            </a>
                                        </Typography>
                                        {/* <Paper variant='outlined'> */}
                                        <div style={{ display: 'flex' }}>
                                            <Typography
                                                variant="body"
                                                sx={{
                                                    backgroundColor: '#e8e8e8',
                                                    padding: 1,
                                                    borderRadius: 1,
                                                    marginRight: 2,
                                                }}
                                            >
                                                {numberFormat(posts?.getProfile?.followers_count)} Followers
                                            </Typography>
                                            <Typography
                                                variant="body"
                                                sx={{
                                                    backgroundColor: '#e8e8e8',
                                                    padding: 1,
                                                    borderRadius: 1,
                                                    marginRight: 2,
                                                }}
                                            >
                                                {numberFormat(posts?.getProfile?.follows_count)} Following
                                            </Typography>
                                            <Typography
                                                variant="body"
                                                sx={{
                                                    backgroundColor: '#e8e8e8',
                                                    padding: 1,
                                                    borderRadius: 1,
                                                    marginRight: 2,
                                                }}
                                            >
                                                {numberFormat(posts?.getProfile?.media_count)} Posts
                                            </Typography>
                                        </div>
                                        {/* <div className="mt-2">
                                            <Typography variant="body" component="div" color="gray">
                                                Biography:{' '}
                                            </Typography>
                                            <Typography variant="body" color="gray">
                                                {posts?.getProfile?.biography}
                                            </Typography>
                                        </div> */}
                                        {/* <div className="mt-2">
                                            <Typography variant="body" component="div" color="gray">
                                                Website:{' '}
                                            </Typography>
                                            <Typography
                                                variant="body"
                                                sx={{
                                                    color: 'gray',
                                                    marginTop: 1,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <a target="_blank" href={posts?.getProfile?.website}>
                                                    {posts?.getProfile?.website}
                                                </a>
                                            </Typography>
                                        </div> */}
                                        {/* </Paper> */}
                                    </Col>
                                </Row>
                            </CardContent>
                            {/* <hr className="separator-line" /> */}
                        </>
                        :
                        <CardContent>No Data Found</CardContent>
                }
            </Card>
            <div className="row allpost-box">
                {!posts?.mediaData?.data.length ? <div className='text-center w-100'>
                    {/* No Posts */}
                </div> : posts?.mediaData?.data.map((item, i) => {
                    return (

                        <div className="col-md-6 col-lg-4 col-xl-3 mb-20 allpost-inner" key={i}>
                            <div className='post-listing-main-box'>
                                <div className='post-listing-image'>
                                    {item?.media_type === "VIDEO" ? (
                                        <>

                                            {/* <button className="btn-link btn-play">
                                    <i class="fa fa-play" aria-hidden="true"></i>
                                </button> */}
                                            {/* <video
                                                // id={`post-video-${item?.post_id}`}
                                                // id={item.post_id}
                                                autoPlay
                                                controls={true}
                                                controlsList="nodownload"
                                            >
                                                <source
                                                    src={item?.media_url + "#t=0.001"}
                                                    type="video/mp4"
                                                ></source>
                                            </video> */}

                                            <ReactPlayer
                                                // className={`review_react_player ${videoData?.orientation === "portrait" ? "" : "objectFit bg-cover"}`}
                                                width={"100%"}
                                                height={"100%"}
                                                url={item?.media_url}
                                                // light={videoData?.banner}
                                                controls
                                                playsinline
                                            />

                                        </>
                                    ) : (

                                        <img
                                            src={item?.media_url}
                                            alt="post-img"
                                            className="post-image"
                                        />
                                    )}
                                </div>
                                <p className='post-para'>{item?.caption}</p>

                            </div>
                        </div>

                    )
                })}
            </div >
        </>
    )
}
