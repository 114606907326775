//export const BASEURL = "http://172.16.1.157:9090/v1/";
//export const BASEURL = 'http://172.16.1.85:9090/v1/';
// export const BASEURL = 'http://172.16.1.85:9090/v1/';
// export const BASEURL = 'http://172.16.1.19:9090/v1/';
// export const BASEURL = 'http://54.81.153.232:9090/v1/';
// export const BASEURL = "https://apiv2.kblive.com/v1/";
// export const BASEURL1 = "https://apiv2.kblive.com/";

//ARAJ IP
// export const BASEURL = "http://172.16.1.85:9090/v1/";
//PARTY IP
// export const BASEURL = 'http://172.16.1.58:9090/v1/'; 

export const BASEURL = "https://backnew.ormelive.com/v1/"; ///live urlss dev.
export const BASEURL1 = "https://backnew.ormelive.com/";
//export const BASEURL = "https://kbserverdev.herokuapp.com/v1/";
// export const BASEURL = 'https://apiv2.konnect.bio/v1/';
// export const BASEURL = 'https://fbkblivebackend.herokuapp.com/v1/';
// export const BASEURL = 'https://fbkblivebackend.herokuapp.com/v1/';
export const visitorURL = "https://kbshop.com";
export const TEMPURL = "https://kbslytest.herokuapp.com/v1/";
